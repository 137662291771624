import React from 'react'
import ProtectedRoute from 'components/ProtectedRoute'
import ErrorBoundary from 'views/ErrorBoundary'
import CustomersView from 'views/CustomerView'
import SuspendedRoute from 'components/SuspendedRoute'
import StandaloneAnalyticsPage from 'components/CustomersView/StandaloneAnalyticsPage'

const OrganizationPage = React.lazy(() =>
  import('components/CustomersView/OrganizationPage')
)

const CustomerDishesPage = React.lazy(() =>
  import('components/CustomersView/DishesPage')
)

const CustomerDishesV3Page = React.lazy(() =>
  import('components/CustomersView/DishesV3Page')
)

const CategoriesPage = React.lazy(() =>
  import('components/CustomersView/CategoriesPage')
)

const KitchensPage = React.lazy(() =>
  import('components/CustomersView/KitchensPage')
)

const RecipesPage = React.lazy(() =>
  import('components/CustomersView/RecipesPage')
)

const RecipesV3Page = React.lazy(() =>
  import('components/CustomersView/RecipesV3Page')
)

const InventoryProductsV3Page = React.lazy(() =>
  import('components/CustomersView/InventoryProductsV3Page')
)

const MenusPage = React.lazy(() => import('components/CustomersView/MenusPage'))
const ServicesPage = React.lazy(() =>
  import('components/CustomersView/ServicesPage')
)

const ServicesMenusPlannerPage = React.lazy(() =>
  import('components/CustomersView/ServicesMenusPlannerPage')
)

const TranslationsPage = React.lazy(() =>
  import('components/CustomersView/TranslationsPage')
)

const QuestionsPage = React.lazy(() =>
  import('components/CustomersView/QuestionsPage')
)

const InventoryItemsPage = React.lazy(() =>
  import('components/CustomersView/InventoryItemsPage')
)

const AnalyticsPage = React.lazy(() =>
  import('components/CustomersView/AnalyticsPage')
)

const customersRoutes = [
  {
    path: 'analytics/:organizationId',
    errorElement: <ErrorBoundary />,
    element: <StandaloneAnalyticsPage />,
  },
  {
    path: 'customers',
    errorElement: <ErrorBoundary />,
    element: (
      <ProtectedRoute roles={['admin', 'customer']}>
        <CustomersView />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'organization',
        element: (
          <SuspendedRoute>
            <OrganizationPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'kitchens',
        element: (
          <SuspendedRoute>
            <KitchensPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'recipes',
        element: (
          <SuspendedRoute>
            <RecipesPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'recipes-v3',
        element: (
          <SuspendedRoute>
            <RecipesV3Page />
          </SuspendedRoute>
        ),
      },
      {
        path: 'inventoryProducts-v3',
        element: (
          <SuspendedRoute>
            <InventoryProductsV3Page />
          </SuspendedRoute>
        ),
      },
      {
        path: 'dishes',
        element: (
          <SuspendedRoute>
            <CustomerDishesPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'dishes-v3',
        element: (
          <SuspendedRoute>
            <CustomerDishesV3Page />
          </SuspendedRoute>
        ),
      },
      {
        path: 'categories',
        element: (
          <SuspendedRoute>
            <CategoriesPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'menus',
        element: (
          <SuspendedRoute>
            <MenusPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'servicesMenusPlanner',
        element: (
          <SuspendedRoute>
            <ServicesMenusPlannerPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'services',
        element: (
          <SuspendedRoute>
            <ServicesPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'analytics',
        errorElement: <ErrorBoundary />,
        element: <AnalyticsPage />,
      },
      {
        path: 'questions',
        element: (
          <SuspendedRoute>
            <QuestionsPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'inventoryItems',
        element: (
          <SuspendedRoute>
            <InventoryItemsPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'translations',
        element: (
          <SuspendedRoute>
            <TranslationsPage />
          </SuspendedRoute>
        ),
      },
    ],
  },
]

export default customersRoutes
