import React from 'react'
import KitchensIcon from '@mui/icons-material/Store'
import TranslationsIcon from '@mui/icons-material/Translate'
import OrganizationIcon from '@mui/icons-material/AccountBalance'
import QuestionsIcon from '@mui/icons-material/Quiz'
import OrganizationProvider from 'contexts/organization'
import ViewWrapper from './ViewWrapper'
import { useTranslation } from 'react-i18next'
import LanguageProvider from 'contexts/language'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'contexts/user'
import BrandProvider from 'contexts/brand'
import { Badge } from '@mui/material'
import EntityIcon from 'components/EntityIcon'
import AnalyticsIcon from '@mui/icons-material/Analytics'

const CustomerView = () => {
  const { t } = useTranslation()
  const { isAdmin } = useUser()

  const drawerTabs = [
    {
      name: t('tabs.customers.organization'),
      route: '/customers/organization',
      icon: <OrganizationIcon />,
    },
    {
      name: t('tabs.customers.kitchens'),
      route: '/customers/kitchens',
      icon: <KitchensIcon />,
    },
    {
      name: t('tabs.customers.inventoryItems'),
      route: '/customers/inventoryItems',
      icon: <EntityIcon type="inventoryItem" />,
    },
    {
      name: t('tabs.customers.recipes'),
      route: '/customers/recipes',
      icon: <EntityIcon type="recipe" />,
    },

    {
      name: t('tabs.customers.dishes'),
      route: '/customers/dishes',
      icon: <EntityIcon type="dish" />,
    },
    {
      name: t('tabs.customers.categories'),
      route: '/customers/categories',
      icon: <EntityIcon type="category" />,
    },
    {
      name: t('tabs.customers.menus'),
      route: '/customers/menus',
      icon: <EntityIcon type="menu" />,
    },
    {
      name: t('tabs.customers.services'),
      route: '/customers/services',
      icon: <EntityIcon type="service" />,
    },
    {
      name: t('tabs.customers.servicesMenusPlanner'),
      route: '/customers/servicesMenusPlanner',
      icon: <EntityIcon type="servicesMenusPlanner" />,
    },
    {
      name: t('tabs.customers.analytics'),
      route: '/customers/analytics',
      icon: <AnalyticsIcon />,
    },
    {
      name: t('tabs.customers.questions'),
      route: '/customers/questions',
      icon: <QuestionsIcon />,
    },

    {
      name: t('tabs.customers.translations'),
      route: '/customers/translations',
      icon: <TranslationsIcon />,
    },
    isAdmin && {
      name: `${t('tabs.customers.recipes')} V3`,
      route: '/customers/recipes-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="RECIPE" />
        </Badge>
      ),
    },
    isAdmin && {
      name: `${t('tabs.customers.inventoryProducts')} V3`,
      route: '/customers/inventoryProducts-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="INVENTORYPRODUCT" />
        </Badge>
      ),
    },
    isAdmin && {
      name: `${t('tabs.customers.dishes')} V3`,
      route: '/customers/dishes-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="DISH" />
        </Badge>
      ),
    },
  ].filter(Boolean)

  return <WrappedViewWrapper drawerTabs={drawerTabs} />
}

export default function WrappedCustomerView() {
  return (
    <OrganizationProvider>
      <BrandProvider>
        <LanguageProvider>
          <CustomerView />
        </LanguageProvider>
      </BrandProvider>
    </OrganizationProvider>
  )
}

const WrappedViewWrapper = ({ drawerTabs }) => {
  const navigate = useNavigate()

  const { signout } = useUser()

  const handleLogout = () => {
    signout()
    navigate('/customers')
  }
  return <ViewWrapper drawerTabs={drawerTabs} onLogout={handleLogout} />
}
