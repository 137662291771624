import { BarChart } from '@mui/x-charts/BarChart'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { DAYS_NAMES } from 'utils/datesDaysAndTimes'

const RELEVANT_DAYS = DAYS_NAMES.slice(1, 6)

const getClassifiedDinersByDay = (
  dinersClassification,
  classificationField
) => {
  return (day) =>
    dinersClassification?.[classificationField]?.reduce((acc, diner) => {
      if (
        diner?.preferences?.on_premise_days?.includes(day) ||
        diner?.preferences?.on_premise_days?.includes('everyday')
        // || diner?.preferences?.on_premise_days?.includes('changes_weekly')
      ) {
        return acc + 1
      }
      return acc
    }, 0)
}
export default function DinersClasificationByDayBarChart({
  dinersClassification,
}) {
  const { t } = useTranslation()
  const xLabels = RELEVANT_DAYS

  const unrestrictedRegularsData = RELEVANT_DAYS.map(
    getClassifiedDinersByDay(dinersClassification, 'unrestrictedRegulars')
  )

  const restrictedRegularsData = RELEVANT_DAYS.map(
    getClassifiedDinersByDay(dinersClassification, 'restrictedRegulars')
  )

  const occasionalMenuDrivenFlexiblesData = RELEVANT_DAYS.map(
    getClassifiedDinersByDay(
      dinersClassification,
      'occasionalMenuDrivenFlexibles'
    )
  )

  const nonCafetiriaData = RELEVANT_DAYS.map(
    getClassifiedDinersByDay(dinersClassification, 'nonCafetiria')
  )

  return (
    <BarChart
      height={500}
      series={[
        {
          data: unrestrictedRegularsData,
          label: t('analytics_page.unrestrictedRegulars'),
          id: 'unrestrictedRegulars',
          stack: 'total',
        },
        {
          data: restrictedRegularsData,
          label: t('analytics_page.restrictedRegulars'),
          id: 'restrictedRegulars',
          stack: 'total',
        },
        {
          data: occasionalMenuDrivenFlexiblesData,
          label: t('analytics_page.occasionalMenuDrivenFlexibles'),
          id: 'occasionalMenuDrivenFlexibles',
          stack: 'total',
        },
        {
          data: nonCafetiriaData,
          label: t('analytics_page.nonCafetiria'),
          id: 'nonCafetiria',
          stack: 'total',
        },
      ]}
      xAxis={[{ data: xLabels, scaleType: 'band' }]}
    />
  )
}
