import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { styled, ThemeProvider, useTheme } from '@mui/material/styles'
import {
  Box,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useScrollTrigger,
  Slide,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { Button, Stack, Tooltip, Typography } from '@mui/material'

import RTL from 'components/RTL'
import getTheme from '../theme'
import LanguageProvider, { useLanguage } from 'contexts/language'
import { useTranslation } from 'react-i18next'
import { useUser } from 'contexts/user'
import OrganizationSelector from 'components/OrganizationSelector'
import BrandSelector from 'components/BrandSelector'
import { useBrand } from 'contexts/brand'
import { useOrganization } from 'contexts/organization'
import Logo from 'components/Logo'
//import DinerSimulator from 'components/DinerSimulator'

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, isMobile }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    !isMobile && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const ItemList = ({ open, tabName, tabRoute, icon }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const selectedTab = location?.pathname?.split('/')?.[2]

  const handleClick = () => {
    navigate(tabRoute)
    document.title = 'EAT - ' + tabName
  }

  return (
    <Tooltip title={tabName} placement="right">
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          selected={selectedTab === tabName}
          onClick={handleClick}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={tabName} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  )
}

function HideOnScroll(props) {
  const { children, target, disabled } = props

  const trigger = useScrollTrigger({
    target: target ? target : undefined,
  })

  if (disabled) {
    return <>{children}</>
  }

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <Box width={'100%'} height={'60px'} position={'absolute'} zIndex={1}>
        {children}
      </Box>
    </Slide>
  )
}

const CustomAppBar = ({
  isMobile,
  isDrawerOpen,
  appBarContent,
  handleDrawerOpen,
  handleClickLogout,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const location = useLocation()
  const { isAdmin } = useUser()
  const { brandId, onSetBrandId } = useBrand()
  const { organizationId, onSetOrganizationId } = useOrganization()
  const isCustomersView =
    location?.pathname?.split('/')?.[1].toLocaleLowerCase() === 'customers'

  return (
    <AppBar
      position="fixed"
      open={isDrawerOpen}
      isMobile={isMobile}
      sx={isMobile && { zIndex: 1 }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(isDrawerOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>

        {isMobile && <Box flex={1} />}

        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <img
            style={{
              width: '100%',
              height: '100%',
            }}
            src={'/long-logo.png'}
            alt=""
          />
        </Stack>

        {!isMobile && (
          <Stack direction={'row'} gap={2} ml={2}>
            {isCustomersView && isAdmin && (
              <OrganizationSelector
                value={organizationId}
                onChange={onSetOrganizationId}
              />
            )}

            {isCustomersView && (
              <BrandSelector
                value={brandId}
                onChange={onSetBrandId}
                organizationId={organizationId}
              />
            )}
          </Stack>
        )}

        {!isMobile && (
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ marginRight: theme.spacing(2) }}></Typography>
            {appBarContent}
          </Box>
        )}

        {!isMobile && (
          <Button
            variant="text"
            sx={{ color: theme.palette.common.white }}
            onClick={handleClickLogout}
          >
            {t('logout')}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )
}

const WebCustomDrawer = ({ isDrawerOpen, drawerTabs, handleDrawerClose }) => {
  const { isRTL } = useLanguage()
  const theme = useTheme()
  const navigate = useNavigate()
  const { isAdmin } = useUser()
  const location = useLocation()
  const isCustomersView =
    location?.pathname?.split('/')?.[1].toLocaleLowerCase() === 'customers'

  return (
    <Drawer variant={'permanent'} open={isDrawerOpen}>
      <DrawerHeader>
        {isAdmin && (
          <Button
            color="info"
            variant="contained"
            fullWidth
            onClick={() => navigate(isCustomersView ? '/admin' : '/customers')}
            sx={{ margin: theme.spacing(0, 2) }}
          >
            {isCustomersView ? 'to admin view' : 'to customers view'}
          </Button>
        )}

        <IconButton onClick={handleDrawerClose}>
          {isRTL ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {drawerTabs.map((tab, index) => (
          <ItemList
            open={isDrawerOpen}
            key={tab.name + index}
            tabName={tab.name}
            tabRoute={tab.route}
            icon={tab.icon}
          />
        ))}
      </List>
    </Drawer>
  )
}

const MobileCustomDrawer = ({
  isDrawerOpen,
  drawerTabs,
  handleDrawerClose,
  isMobile,
  onLogout,
}) => {
  const { t } = useTranslation()
  const { isRTL } = useLanguage()
  const theme = useTheme()
  const navigate = useNavigate()
  const { isAdmin } = useUser()
  const location = useLocation()
  const isCustomersView =
    location?.pathname?.split('/')?.[1].toLocaleLowerCase() === 'customers'
  const { brandId, onSetBrandId } = useBrand()
  const { organizationId, onSetOrganizationId } = useOrganization()

  return (
    <MuiDrawer
      open={isDrawerOpen}
      isMobile={isMobile}
      onClose={handleDrawerClose}
    >
      <DrawerHeader sx={{ minHeight: 'auto', p: 1 }}>
        <Stack gap={2}>
          <Stack direction="row">
            {isAdmin && (
              <Button
                color="info"
                variant="contained"
                fullWidth
                onClick={() =>
                  navigate(isCustomersView ? '/admin' : '/customers')
                }
                sx={{ margin: theme.spacing(0, 2) }}
              >
                {isCustomersView ? 'to admin view' : 'to customers view'}
              </Button>
            )}

            <IconButton onClick={handleDrawerClose}>
              {isRTL ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </Stack>

          {isCustomersView && isAdmin && (
            <OrganizationSelector
              value={organizationId}
              onChange={onSetOrganizationId}
            />
          )}

          {isCustomersView && (
            <BrandSelector
              value={brandId}
              onChange={onSetBrandId}
              organizationId={organizationId}
            />
          )}
        </Stack>
      </DrawerHeader>
      <Divider />
      <List>
        {drawerTabs.map((tab, index) => (
          <ItemList
            open={isDrawerOpen}
            key={tab.name + index}
            tabName={tab.name}
            tabRoute={tab.route}
            icon={tab.icon}
          />
        ))}
      </List>

      <Box flex={1} />

      <Button variant="outlined" color="error" onClick={onLogout} sx={{ m: 1 }}>
        {t('logout')}
      </Button>
    </MuiDrawer>
  )
}

const ViewPicker = ({ drawerTabs = [], appBarContent, onLogout }) => {
  const { isRTL } = useLanguage()
  const isMobile = useMediaQuery('(max-width: 600px)')
  const mainRef = React.useRef()

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  const handleClickLogout = () => {
    onLogout()
  }

  return (
    <ThemeProvider theme={getTheme(isRTL)}>
      <Box sx={{ display: 'flex', height: '100dvh' }}>
        <CssBaseline />

        <HideOnScroll target={mainRef.current} disabled={!isMobile}>
          <CustomAppBar
            isMobile={isMobile}
            isDrawerOpen={isDrawerOpen}
            appBarContent={appBarContent}
            handleDrawerOpen={handleDrawerOpen}
            handleClickLogout={handleClickLogout}
          />
        </HideOnScroll>

        {!isMobile && (
          <WebCustomDrawer
            isMobile={isMobile}
            drawerTabs={drawerTabs}
            isDrawerOpen={isDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            onLogout={handleClickLogout}
          />
        )}

        <Stack component="main" flexGrow={1} overflow={'auto'} ref={mainRef}>
          <DrawerHeader />
          <Outlet />
        </Stack>
      </Box>

      {isMobile && (
        <MobileCustomDrawer
          isMobile={isMobile}
          drawerTabs={drawerTabs}
          isDrawerOpen={isDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          onLogout={handleClickLogout}
        />
      )}
    </ThemeProvider>
  )
}

const ViewWrapper = (props) => {
  const { isRTL } = useLanguage()

  if (!isRTL) return <ViewPicker {...props} />

  return (
    <RTL>
      <ViewPicker {...props} />
    </RTL>
  )
}

function WrappedWrappedViewWrapper(props) {
  return (
    <LanguageProvider>
      <ViewWrapper {...props} />
    </LanguageProvider>
  )
}

export default WrappedWrappedViewWrapper
