import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useOrganizations } from 'hooks/organizations'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function OrganizationSelector({ value, onChange }) {
  const { t } = useTranslation()
  const { organizations, isLoading: isLoadingOrganizations } =
    useOrganizations()

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  React.useEffect(() => {
    if (!value && organizations?.length) {
      onChange(organizations[0]._id)
    }
  }, [onChange, organizations, value])

  if (!value) return null

  return (
    <FormControl size="small" sx={{ minWidth: 200 }}>
      <InputLabel id="organization-select-label">
        {t('organization')}
      </InputLabel>
      <Select
        labelId="organization-select-label"
        id="organization-select"
        value={value}
        label="organization"
        onChange={handleChange}
        disabled={isLoadingOrganizations}
      >
        {organizations?.map((organization) => (
          <MenuItem value={organization._id} key={organization._id}>
            {organization.operationalName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
